import React from "react"
import { LocationIcon } from "../Icons"
import { ListInfo, ListItemInfo } from "../AddressListStyle"


const ContactList = () => {
   
    return (
        <ListInfo className="list-info">
            <ListItemInfo className="list-item">
            <span className="icon"><LocationIcon /></span><span className="text">12721 I-27 South, Amarillo, TX 79119</span>
            </ListItemInfo>
            {/* <ListItemInfo className="list-item">
            <span className="icon"><EmailIcon /></span><span className="text"><a href="mailto:info@panhandlemetals.com">info@panhandlemetals.com</a></span>
            </ListItemInfo> */}
        </ListInfo>
    )
}

export default ContactList
